<!--
  Component Name: Price Transaction View
  Description: This component represents the UI layout for viewing uploaded price transactions. It includes a header section with action buttons and a content section to display data in a table format. The component also supports downloading a template and notifying the parent component for cancellation.

  Key Features:
  - Displays a list of uploaded price transactions in a data table.
  - Allows users to download a template file.
  - Provides a cancel button to close the component.
  - Includes a loader to indicate data fetching or processing.

  Dependencies:
  - app-admin-button: A reusable button component with customizable labels and actions.
  - app-admin-data-table: A reusable data table component for displaying tabular data with pagination and status mapping.
  - ngx-loading: A third-party loader component for showing loading states.
-->

<div class="container-wrapperpadding-medium">
  <!-- Header Section -->
  <div
    class="header d-flex justify-content-between align-items-center padding-medium"
  >
    <div class="header-title-section">
      <div
        class="title-wrapper d-flex justify-content-start align-items-center"
      >
        <!-- Page Title -->
        <h3 class="page-title">Price Transaction View</h3>
      </div>
    </div>
    <div class="header-actions gap-24">
      <!-- Download Template Button -->
      <app-admin-button
        [label]="'Download Data'"
        [class]="'secondary-btn'"
        [disabled]="false"
        (action)="downloadTemplate()"
      >
      </app-admin-button>

      <!-- Cancel Button -->
      <app-admin-button
        [label]="'Back'"
        [class]="'cancel-btn'"
        [disabled]="false"
        (action)="closeComponent('price-data-view')"
      >
      </app-admin-button>
    </div>
  </div>

  <!-- Content Section -->
  <div class="content-section">
    <app-admin-data-table
      [data]="uploadedPriceDataList"
      [columns]="tableColumn"
      [totalCount]="totalRecords"
      [statusMap]="statusMap"
      (pageChangeAction)="pageChangeAction($event)"
    ></app-admin-data-table>
  </div>
</div>

<!-- Loader Section -->
<div *ngIf="isLoading" class="loader-div">
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>
