<!--
  The following template defines the structure and functionality for the Price Data Upload section.
  It includes a toggleable view for managing price uploads and viewing uploaded price data.
  The layout uses Angular structural directives, reusable components, and dynamic data binding.

  Structure Overview:
  1. **Container Wrapper**:
      - Displays the main content when `isPriceManageView` is `false`.
      - Includes the header with title, file upload, and actions like "View Price Data" and "Download Template".
      - Contains a data table showing the list of uploaded price data.

  2. **Manage Price View**:
      - A separate view rendered when `isPriceManageView` is `true`.
      - Allows detailed management of uploaded prices.

  3. **Loader**:
      - Displays a loading indicator when `isLoading` is `true`.

  Component and Directive Usage:
  - *ngIf*: Toggles visibility of sections based on conditions.
  - app-admin-file-upload: Custom component for file uploads.
  - app-admin-button: Reusable button component with custom actions and styling.
  - app-admin-data-table: Custom data table component for listing and managing data.
  - app-manage-price-upload-view: Component for detailed price management.

  Event Bindings:
  - (getAfterUpload)="AfterUploadFile()": Refreshes data after a file upload.
  - (action)="changeView('')": Toggles back to the default view.
  - (action)="downloadTemplate()": Triggers download of a template.
  - (pageChangeAction)="pageChangeAction($event)": Handles pagination changes.
  - (changeToDefault)="changeView($event)": Handles switching views.

  Properties:
  - isPriceManageView: Boolean flag to toggle between views.
  - isLoading: Boolean flag to display the loader.
  - uploadedPriceDataList: List of uploaded price data to populate the data table.
  - tableColumn: Array defining columns for the data table.
  - totalRecords: Total count of records for pagination.
  - statusMap: Object mapping status codes to labels and colors.
  - buttonConfig: Configuration object for dynamic buttons in the table.
-->

<div class="container-wrapper padding-medium" *ngIf="!isPriceManageView">
  <div
    class="header d-flex justify-content-between align-items-center padding-top-medium padding-bottom-medium margin-bottom-medium"
  >
    <div class="header-title-section">
      <div
        class="title-wrapper d-flex justify-content-start align-items-center"
      >
        <h3 class="page-title">Price Data Upload</h3>
        <app-admin-file-upload
        [getResourceAccess]="getResourceAccess"
          [apiUrl]="apiUrl"
          (getAfterUpload)="AfterUploadFile()"
        ></app-admin-file-upload>
      </div>
    </div>
    <div class="header-actions gap-24">
      <app-admin-button
        [label]="'View Price Data'"
        [class]="'primary-button'"
        [disabled]="false"
        (action)="changeView('')"
      >
      </app-admin-button>
      <app-admin-button
        [label]="'Download Template'"
        [class]="'secondary-btn'"
        [disabled]="false"
        (action)="downloadTemplate()"
      >
      </app-admin-button>
    </div>
  </div>
  <div class="content-section">
    <app-admin-data-table
      [data]="uploadedPriceDataList"
      [columns]="tableColumn"
      [totalCount]="totalRecords"
      [statusMap]="statusMap"
      [buttons]="buttonConfig"
      (pageChangeAction)="pageChangeAction($event)"
    ></app-admin-data-table>
  </div>
</div>
<ng-container *ngIf="isPriceManageView">
  <app-manage-price-upload-view (changeToDefault)="changeView($event)">
  </app-manage-price-upload-view>
</ng-container>

<!-- loader start -->
<div *ngIf="isLoading" class="loader-div">
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>
<!-- loader end -->
