import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AdminMenusService } from './admin-menus.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ShareService } from '../services/share.service'
import { ApiserviceService } from '../../apiservice.service';

interface AdminMenus {
  key: string,
  title?: string,
  icon?: string,
  url: string,
  show: boolean
}

interface userAccess {
  GET: boolean,
  POST: boolean,
  PATCH: boolean,
  DELETE: boolean
}

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_user_resource_post</li>
* </ol>
*
*/

@Component({
  selector: 'admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {
  @Input() size: any;
  adminMenus: AdminMenus[] = [
    // {
    //   key : 'dashboard',
    //   title : "Dashboard",
    //   icon : 'dashboard',
    //   url : '/admin',
    //   show : true
    // },
    // {
    //   key : 'settings',
    //   title : "Settings",
    //   icon : 'settings',
    //   url : '/admin',
    //   show : true
    // },
    {
      key: 'property_settings',
      title: "Property type",
      icon: 'property_type',
      url: '/admin/property',
      show: false
    },
    {
      key: 'masters',
      title: "Masters",
      icon: 'masters',
      url: '/admin/masters',
      show: false
    },
    {
      key: 'attributes',
      title: "Attributes",
      icon: 'attribute_group',
      url: '/admin/attribute',
      show: false
    },
    {
      key: 'field_settings',
      title: "Attribute Settings",
      icon: 'field_settings',
      url: '/admin/field',
      show: false
    },
    {
      key: 'attribute_deletion',
      title: "Attribute Data Deletion",
      icon: 'attribute_deletion',
      url: '/admin/attribute-deletion',
      show: false
    },
    {
      key: 'property_manage',
      title: "Property Management",
      icon: 'property_listing',
      url: '/admin/property',
      show: false
    },
    {
      key: 'assignments',
      title: "Assignments",
      icon: 'assignments',
      url: '/admin/assignment',
      show: false
    },
    {
      key: 'activity_details',
      title: "Activity Details",
      icon: 'activity_detail',
      url: '',
      show: false
    },
    {
      key: 'quality',
      title: "Quality Analysis",
      icon: 'verified',
      url: '/admin/quality',
      show: false
    },
    {
      key: 'csv-upload',
      title: "CSV Upload",
      icon: 'attach',
      url: '/admin/csv-upload',
      show: false
    },
    {
      key: 'new-csv-upload',
      title: "New csv upload",
      icon: 'new_csv',
      url: '/admin/new-csv-upload',
      show: false
    },
    {
      key: 'visibility-calculation',
      title: "Visibility calculation",
      icon: 'visibility_calculation',
      url: '/admin/visibility-calculation',
      show: false
    },
    {
      key: 'price-upload',
      title: "Price Upload",
      icon: 'price_upload',
      url: '/admin/price-upload',
      show: false
    },
    {
      key: 'adm-data',
      title: "ADM Data",
      icon: 'cloud_upload',
      url: '/admin/adm-data',
      show: false
    },
    {
      key: 'in-progress',
      title: "In Progress",
      icon: 'in_progress',
      url: '/admin/in-progress',
      show: false
    },

    // {
    //   key: 'configuration',
    //   title: "Configuration",
    //   icon: 'tool',
    //   url: '/admin/configuration',
    //   show: false
    // },
    {
      key: 'sync-property',
      title: "Snapshot",
      icon: 'synchornize',
      url: '/admin/sync-property',
      show: false
    },
    {
      key: 'sync-property-gis',
      title: "Sync Property Gis",
      icon: 'propertyGis',
      url: '/admin/sync-property-gis',
      show: false
    },
    {
      key: 'historical-sync-data',
      title: "Historical Sync Data",
      icon: 'propertyGis',
      url: '/admin/historical-sync-data',
      show: true
    },
    {
      key: 'settings',
      title: "Settings",
      icon: 'settings',
      url: '/admin/settings',
      show: false
    }


  ];

  attributes_access: userAccess;
  constructor(private menus: AdminMenusService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private fnc: CommonfunctionService, private share: ShareService,
    private api: ApiserviceService) {
    iconRegistry.addSvgIcon(
      'gear',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/gear.svg'))
      .addSvgIcon('activity_detail', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/activity_detail.svg'))
      .addSvgIcon('field_settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/field_settings.svg'))
      .addSvgIcon('masters', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/masters.svg'))
      .addSvgIcon('property_listing', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_listing.svg'))
      .addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/settings.svg'))
      .addSvgIcon('property_type', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_type.svg'))
      .addSvgIcon('attribute_group', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/attribute_group.svg'))
      .addSvgIcon('cloud_upload', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/cloud_upload.svg'))
      .addSvgIcon('assignments', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
      .addSvgIcon('synchornize', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/synchornize.svg'))
      .addSvgIcon('snapshot', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Snapshot_data_normal.svg'))
      .addSvgIcon('propertyGis', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Sync Property GIS_normal.svg'))
      .addSvgIcon('propertyLog', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Sync Property Log_normal.svg'))
      .addSvgIcon('gisDataUpload', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
      .addSvgIcon('in_progress', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/in_progress.svg'))
      .addSvgIcon('attribute_deletion', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icon/attributes-deletion.svg'))
      .addSvgIcon('new_csv', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/new-csv.svg'))
      .addSvgIcon('price_upload', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/price_upload_icon.svg'))
      .addSvgIcon('visibility_calculation', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/observer-adm-upload.svg'));
    this.getAllowResourcesApi();
  }

  ngOnInit() {

  }
  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      })
  }

  isSmall() {
    return this.size === 'small';
  }

  isNormal() {
    return this.size === 'normal';
  }

  getActive(menu) {
    return this.menus.getIsActive(menu.key);
  }

  getAllowResource() {
    //this.attributes_access = this.fnc.checkResourceAccess('attributes');
    this.adminMenus.forEach((v, k) => {
      let resource: userAccess;
      switch (v.key) {
        case 'property_settings':
          resource = this.fnc.checkResourceAccess('propertytypes');
          if (resource.GET) v.show = true;
          break;
        case 'attributes':
          resource = this.fnc.checkResourceAccess('attributes');
          if (resource.GET) v.show = true;
          break;
          // case 'attribute_deletion':
          //   resource = this.fnc.checkResourceAccess('attribute_deletion');
          //   if (resource.GET) v.show = true;
          //   break;
        case 'field_settings':
          resource = this.fnc.checkResourceAccess('attributegroups');
          if (resource.GET) v.show = true;
          break;
        case 'masters':
          resource = this.fnc.checkResourceAccess('masters');
          if (resource.GET) v.show = true;
          break;
        case 'assignments':
          resource = this.fnc.checkResourceAccess('assignments');
          if (resource.GET) v.show = true;
          break;
        case 'quality':
          resource = this.fnc.checkResourceAccess('qa_qc');
          if (resource.GET) v.show = true;
          break;
        case 'csv-upload':
          resource = this.fnc.checkResourceAccess('csv_upload');
          if (resource.GET) v.show = true;
          break;
        case 'configuration':
          resource = this.fnc.checkResourceAccess('reports');
          if (resource.GET) v.show = true;
          break;
        case 'sync-property':
          resource = this.fnc.checkResourceAccess('sync_property');
          if (resource.GET) v.show = true;
          break;
        case 'sync-property-gis':
          resource = this.fnc.checkResourceAccess('sync_gis');
          if (resource.GET) v.show = true;
          break;
        case 'sync-property-log':
          resource = this.fnc.checkResourceAccess('calculateSyncProcess');
          if (resource.GET) v.show = true;
          break;
        case 'in-progress':
          resource = this.fnc.checkResourceAccess('in_progress');
          if (resource.GET) v.show = true;
          break;
          case 'visibility-calculation':
          resource = this.fnc.checkResourceAccess('visibility_calculation');
          if (resource.GET) v.show = true;
          break;
          case 'price-upload':
            resource = this.fnc.checkResourceAccess('price_upload');
            if (resource.GET) v.show = true;
            break;
        case 'adm-data':
          resource = this.fnc.checkResourceAccess('adm_data');
          if (resource.GET) v.show = true;
          break;
        case 'settings':
          resource = this.fnc.checkResourceAccess('settings');
          if (resource.GET) v.show = true;
          break;
        case 'new-csv-upload':
          resource = this.fnc.checkResourceAccess('csv_upload');
          if (resource.GET) v.show = true;
          break;
      }
    });
  }
}
