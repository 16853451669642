import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-admin-button',
  templateUrl: './admin-button.component.html',
  styleUrls: ['./admin-button.component.scss']
})
export class AdminButtonComponent {
  @Input() label: string = ''; // Button label
  @Input() disabled: boolean = false; // Disable state
  @Input() style: any = {}; // Inline styles
  @Input() class: string = ''; // CSS classes
  @Output() action: EventEmitter<void> = new EventEmitter(); // Event emitter for actions

  onClick() {
    if (!this.disabled) {
      this.action.emit(); // Emit the action when button is clicked
    }
  }
}
