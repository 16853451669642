/**
 * Component: ManagePriceUploadViewComponent
 *
 * Description:
 * This component is responsible for managing the uploaded price data view.
 * It interacts with the backend API to fetch, display, and manage price data records.
 * The component also provides functionalities to download templates and handle pagination.
 * It emits events to notify the parent component about certain actions.
 *
 * Key Features:
 * - Fetch and display uploaded price data.
 * - Manage pagination with customizable limit and offset.
 * - Download a price data template from the server.
 * - Emit events to inform the parent component.
 */

import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { ApiserviceService } from '../../../../../../src/app/apiservice.service';

@Component({
  selector: 'app-manage-price-upload-view',
  templateUrl: './manage-price-upload-view.component.html',
  styleUrls: ['./manage-price-upload-view.component.scss']
})
export class ManagePriceUploadViewComponent {
  @Output() changeToDefault = new EventEmitter<void>(); //EventEmitter to notify the parent component to reset to the default state.
  totalRecords: number = 0; // Total number of records fetched from the API.
  selectedData: [] = []; // Holds the selected data from the table (if applicable).
  isLoading: boolean = false; // Loading state to indicate API calls in progress.
  //Table columns to display data.
  tableColumn: string[] = [
    'id',
    'entity',
    'property_uid',
    'unit_uid',
    // 'category',
    'price_type',
    'price_value',
    'date',
    'outlier',
    'source',
    'source_name',
    'government_id',
    'remarks',
    'added_date',
    'added_by',
    'updated_date',
    'updated_by',
    'status',
  ];

  uploadedPriceDataList: any[] = []; // List of uploaded price data fetched from the API.
  //Status mapping object to define labels and color codes for different statuses.
  statusMap = {
    "-1": { label: "Invalid", color: "#FF4A4A" },
    "1": { label: "Valid", color: "#81A2FF" },
    "2": { label: "Accepted", color: "#0FEB03" },
    "-2": { label: "Accept Invalid", color: "#E3DB05" },
    "0": { label: "Uploaded", color: "#AAAAAA" },
    "-3": { label: "Rejected", color: "#FF4A4A" },
    "4": { label: "Accept Progress", color: "#FF8A3E" },
    "3": { label: "Validating", color: "#ACA710" },
  };
  limit: number = 10; //Number of records to fetch per page.
  offset: number = 0; // Offset for paginated API requests.

  constructor(
    private apiService: ApiserviceService, // Service to handle API requests
    private cdr: ChangeDetectorRef // Change detector to manage view updates
  ) {}

  /**
   * Lifecycle hook: Initializes the component and fetches initial data.
   */
  ngOnInit(): void {
    this.fetchUploadedPriceData();
  }

  /**
   * Fetches the uploaded price data from the API.
   * Handles loading state and updates the view upon success or failure.
   */
  fetchUploadedPriceData() {
    const apiUrl = `/price-upload/view-data?limit=${this.limit}&offset=${this.offset}&is_count=true`;
    this.isLoading = true;
    this.apiService.getEpsData(apiUrl).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.uploadedPriceDataList = [...response.data];
        this.totalRecords = response.totalRecord;
        this.cdr.detectChanges(); // Notify Angular explicitly
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  /**
   * Downloads the price data template from the server.
   */
  downloadTemplate() {
    const apiUrl = `download-price-data?user_id=${this.apiService.user_id}`;
    this.isLoading = true;
    this.apiService.getEpsData(apiUrl).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        window.open(response.data, "_blank");
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  /**
   * Handles pagination changes and fetches the updated data.
   * @param event - Pagination event containing the updated limit and offset.
   */
  pageChangeAction(event: { limit: number; offset: number }) {
    this.limit = event.limit;
    this.offset = event.offset;
    this.fetchUploadedPriceData();
  }

  /**
   * Emits an event to notify the parent component to close the child component.
   * @param value - Optional value to pass to the parent component.
   */
  closeComponent(value?: any): void {
    this.changeToDefault.emit(value);
  }
}
